import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

import PageComponentGroups from "../components/PageComponentGroups"

const Page = props => {
  const { components, seo } = props.data
  return (
    <Layout>
      <Seo
        title={seo.pageSeoData.swbThemeMetaTitle}
        description={seo.pageSeoData.swbThemeDescription}
        metaImg={seo.pageSeoData.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <PageComponentGroups components={components} />
    </Layout>
  )
}

export const pageTempQuery = graphql`
  query pageTempPage($id: String!) {
    seo: wpPage(id: { eq: $id }) {
      pageSeoData {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    components: wpPage(id: { eq: $id }) {
      acfMainTemplateFields {
        pageComponents {
          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroContent {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            content
            subTitle
            title
            secondButtonRequired
            secondButtonText
            secondButtonFile {
              mediaItemUrl
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroContentTwo {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            content
            subTitle
            title
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroContentThree {
            buttonRequired
            buttonSlug
            buttonText
            content
            fieldGroupName
            title
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_IconLinks {
            fieldGroupName
            links {
              linkSlug
              linkText
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_SideBySideContentBlocks {
            fieldGroupName
            blockColourOne
            blockColourTwo
            buttonRequiredOne
            buttonRequiredTwo
            buttonSlugOne
            buttonSlugTwo
            buttonTextOne
            buttonTextTwo
            contentOne
            contentTwo
            titleOne
            titleTwo
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentBesideSmallImage {
            fieldGroupName
            blockColour
            content
            reversed
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentWithIconsBlocks {
            fieldGroupName
            mainTitle
            contentBlocks {
              buttonRequired
              buttonSlug
              buttonText
              content
              fieldGroupName
              titleUnderIcon
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_LogosLinks {
            fieldGroupName
            title
            graphicRequired
            logos {
              fieldGroupName
              linkRequired
              linkUrl
              linkVisualAidText
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageByContent {
            content
            fieldGroupName
            topGraphicActive
            buttonRequired
            buttonText
            buttonSlug
            secondButtonRequired
            secondButtonType
            secondButtonExternalUrl
            secondButtonInternalSlug
            secondButtonFile {
              mediaItemUrl
            }
            secondButtonText
            thirdButtonRequired
            thirdButtonType
            thirdButtonExternalUrl
            thirdButtonInternalSlug
            thirdButtonFile {
              mediaItemUrl
            }
            thirdButtonText
            fourthButtonRequired
            fourthButtonType
            fourthButtonExternalUrl
            fourthButtonInternalSlug
            fourthButtonFile {
              mediaItemUrl
            }
            fourthButtonText
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_SideBySideImageOverContent {
            contentOne
            contentTwo
            fieldGroupName
            imageOne {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            imageTwo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentBySmallImage {
            content
            fieldGroupName
            subText
            title
            topGraphicActive
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_BannerCallout {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            content
            title
            backgroundColor
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentWithImageEven {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            content
            contentRequired
            reverse
            title
            titleColour
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentWithImageSeventy {
            fieldGroupName
            buttonRequired
            buttonSlug
            buttonText
            content
            contentRequired
            reverse
            title
            titleColour
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ContentThenColumnsSection {
            fieldGroupName
            content
            title
            columns {
              backgroundColor
              buttonRequired
              buttonSlug
              buttonText
              fieldGroupName
              title
              wysiwyg
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CommunityAdvisoryCommittee {
            fieldGroupName
            showTheCommunityAdvisoryCommittee
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageContentWithSocialLinks {
            bottomContent
            fieldGroupName
            title
            titleTag
            subTitle
            subTitleTag
            topContent
            socialLinksRequired
            buttonRequired
            buttonText
            buttonType
            buttonUrl
            buttonSlug
            content
            titleIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500)
                }
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ProgramsCategoriesNavigation {
            displayTheListOfProgramsCategories
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_BannerCalloutIcon {
            buttonRequired
            buttonSlug
            buttonText
            content
            fieldGroupName
            title
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_BannerCalloutNoText {
            buttonRequired
            buttonSlug
            buttonText
            fieldGroupName
            title
            socialMediaLinks
            iconRequired
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_SimpleTitleWithContent {
            content
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ProcessSteps {
            fieldGroupName
            title
            steps {
              content
              fieldGroupName
              title
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygWithHeaderAndTitles {
            fieldGroupName
            iconRequired
            mainTitle
            mainTitleTag
            subTitle
            subTitleRequired
            topBigFontContent
            topContentRequired
            topGraphicRequired
            wysiwyg
            sectionSize
            logosRequired
            logos {
              url
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700)
                  }
                }
              }
            }

            titleIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_FamilyAssessmentForm {
            displayForm
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_HeroJustAnImage {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_LightBlueTitleBesideImage {
            fieldGroupName
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CentreAlignedTitleAndContent {
            content
            fieldGroupName
            title
            titleTag
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ResearchConsentContactForm {
            displayResearchConsentContactForm
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_MailingList {
            content
            fieldGroupName
            title
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_CircleImageByContent {
            fieldGroupName
            blocks {
              content
              fieldGroupName
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_VolunteerSignUpForm {
            displayVolunteerSignUpForm
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_DisplayNewAndMediaArticles {
            display
            fieldGroupName
            introContent
            title
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_Wysiwyg {
            fieldGroupName
            wysiwyg
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_DisplayLatestNewsArticle {
            display
            fieldGroupName
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_PointsSection {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
            topPoints {
              title
              content
            }
            bottomTitle
            titleTag
            bottomPoints {
              title
              content
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_OurStaff {
            fieldGroupName
            title
            teamMembers {
              nameTitle
              bio
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 750)
                  }
                }
              }
            }
          }
          ... on WpPage_Acfmaintemplatefields_PageComponents_WysiwygImageTitles {
            fieldGroupName
            subTitle
            subTitleTag
            title
            titleTag
            wysiwyg
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageBoldContent {
            content
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_ImageWithList {
            fieldGroupName
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750)
                }
              }
            }
            listItems {
              item
              linkRequired
              link
            }
          }

          ... on WpPage_Acfmaintemplatefields_PageComponents_JobPosting {
            jobPostings {
              jobPostTitle
              jobPostLink
              jobPostContent
            }
            fieldGroupName
          }
        }
      }
    }
  }
`

export default Page
